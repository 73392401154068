import * as React from "react";
import {StyledSocialMediaItem, StyledList} from "./style";
import {Props} from "./interface";
import {socialMediaIcons} from "@data/socialMediaIcons";

export const SocialMedia = ({social, ...rest}: Props) => {
	return (
		<StyledList {...rest}>
			{social &&
				social.map(({name, url}) => {
					const Icon = socialMediaIcons[name];

					return (
						<li key={name}>
							<StyledSocialMediaItem href={url} target="_blank" aria-label={`go to ${name}`}>
								<Icon />
							</StyledSocialMediaItem>
						</li>
					);
				})}
		</StyledList>
	);
};
