import styled from "styled-components";

export const StyledList = styled.ul`
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-flow: row wrap;
	gap: clamp(10px, 2vw, 20px);

	padding: 0px;

	list-style: none;
`;

export const StyledSocialMediaItem = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;

	width: clamp(25px, 8vw, 50px);
	height: clamp(25px, 8vw, 50px);

	border: 1px solid rgba(255, 255, 255, 0.541);
	border-radius: 50%;
	outline: none;

	transition: all 100ms;

	&:hover,
	&:focus {
		background: ${({theme}) => theme.invertedTextColor};
	}

	&:hover svg,
	&:focus svg {
		fill: ${({theme}) => theme.primaryColor};
	}

	svg {
		width: clamp(15px, 4vw, 25px);
		height: clamp(15px, 4vw, 25px);
	}
`;
