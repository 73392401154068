import * as React from "react";
import {useContext} from "react";
import {LocalizedLink} from "@components/LocalizedLink";
import {LocaleContext} from "@context/LocaleContext";
import {Logo} from "@components/Logo";
import {toggleLanguage} from "@data/translation/toggleLanguage";
import {StyledNavBar, StyledToggleLanguage, NavBarSpace} from "./style";
import {Burger} from "@components/Burger";
import {URLContext} from "@context/URLContext";
import {Props} from "./interface";
import {ToggleTheme} from "@components/ToggleTheme";

export const NavBar = ({localized = true}: Props) => {
	const locale = useContext(LocaleContext);
	const {pathname} = useContext(URLContext);
	const removeLocalePath = /(\/e(s|n)|)(\/*|)/;
	const pathnameWithoutLocale = pathname.replace(removeLocalePath, "");

	return (
		<StyledNavBar>
			<NavBarSpace />
			<LocalizedLink to="/" className="logo">
				<Logo />
			</LocalizedLink>
			{localized && (
				<StyledToggleLanguage to={`/${pathnameWithoutLocale}`} toggleLocale className="language">
					{toggleLanguage[locale]}
				</StyledToggleLanguage>
			)}
			<ToggleTheme className="theme" />
			<Burger className="burger"></Burger>
		</StyledNavBar>
	);
};
