import * as React from "react";
import {StyledFooter} from "./style";
import {useContext} from "react";
import {LocaleContext} from "@context/LocaleContext";
import {SocialMedia} from "@components/SocialMedia";
import {socialMediaItems} from "@data/socialMediaItems";
import {credits} from "@data/translation/credits";

export const Footer = () => {
	const locale = useContext(LocaleContext);

	return (
		<StyledFooter>
			<p>{credits[locale].quote}</p>
			<p>{credits[locale].credits}</p>
			<SocialMedia social={socialMediaItems}></SocialMedia>
		</StyledFooter>
	);
};
