import * as React from "react";
import {HTMLAttributes} from "react";
import {useTheme} from "styled-components";
import {CheckBox, Indicator, StyledThemeIcon, StyledToggle, ToggleRail} from "./style";

export const ToggleTheme = ({...props}: HTMLAttributes<HTMLElement>) => {
	const theme = useTheme();

	const toggleTheme = () => {
		theme.toggleTheme();
	};

	return (
		<StyledToggle htmlFor="toggle-theme" tabIndex={0} {...props}>
			<ToggleRail>
				<CheckBox
					type="checkbox"
					id="toggle-theme"
					name="toggle-theme"
					checked={theme.type === "dark"}
					onChange={toggleTheme}
				/>
				<Indicator checked={theme.type === "dark"}>
					<StyledThemeIcon />
				</Indicator>
			</ToggleRail>
		</StyledToggle>
	);
};
