import * as React from "react";
import {Link} from "gatsby";
import {useContext, useState, FocusEvent, HTMLAttributes, FC} from "react";
import {LocaleContext} from "@context/LocaleContext";
import {LocalizedLink} from "@components/LocalizedLink";
import {navBarItems} from "@data/translation/navBarItems";
import {StyledMenu, StyledLinks, StyledCheckbox, StyledBurger, BurgerContainer} from "./style";

export const Burger = (props: HTMLAttributes<HTMLElement>) => {
	const locale = useContext(LocaleContext);
	const [checked, setChecked] = useState(false);

	const handleBlur = (event: FocusEvent<HTMLDivElement, Element>) => {
		const currentTarget = event.currentTarget;

		setTimeout(() => {
			if (!currentTarget.contains(document.activeElement)) {
				setChecked(false);
			}
		}, 0);
	};

	return (
		<StyledMenu {...props} tabIndex={0} onBlur={handleBlur}>
			<StyledCheckbox type="checkbox" id="checkbox" readOnly checked={checked} />
			<BurgerContainer
				className="burger-container"
				htmlFor="checkbox"
				onClick={() => {
					setChecked(!checked);
				}}>
				<StyledBurger className="burger" />
			</BurgerContainer>
			<StyledLinks>
				{navBarItems.map(({slug, path, content, inPage}) => {
					return (
						<li key={slug}>
							{inPage ? (
								<Link to={path}>{content[locale]}</Link>
							) : (
								<LocalizedLink to={path}>{content[locale]}</LocalizedLink>
							)}
						</li>
					);
				})}
				{props.children}
			</StyledLinks>
		</StyledMenu>
	);
};
