import * as React from "react";
import {Locale} from "@data/locales";
import {createContext, FC, PropsWithChildren, useState, useEffect} from "react";
import {setTimeout} from "timers/promises";

interface Props extends PropsWithChildren {
	locale: unknown;
}

export const LocaleContext = createContext<Locale>("en");

export const LocaleProvider: FC<Props> = (props) => {
	const uncheckedLocale = props.locale;
	const checkedLocale = uncheckedLocale === "es" || uncheckedLocale === "en" ? uncheckedLocale : "en";

	const [locale, setLocale] = useState<Locale>(checkedLocale);

	useEffect(() => {
		// change locale after page transition
		window.setTimeout(() => {
			setLocale(checkedLocale);
		}, 400);
	}, [uncheckedLocale]);

	return <LocaleContext.Provider value={locale}>{props.children}</LocaleContext.Provider>;
};
