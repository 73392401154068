import * as React from "react";
import {useEffect} from "react";
import {Themes} from "@interfaces/Themes";
import {themes} from "@global/theme";
import {ThemeProvider} from "styled-components";
import {FC, PropsWithChildren, useState} from "react";

export const StyledThemeProvider: FC<PropsWithChildren> = (props) => {
	const [theme, setTheme] = useState<Themes>("dark");
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setTheme(localStorage.getItem("theme") === "light" ? "light" : "dark");
		setMounted(true);
	}, []);

	const toggleTheme = () => {
		const toggledTheme: Themes = theme === "light" ? "dark" : "light";
		setTheme(toggledTheme);
		localStorage.setItem("theme", toggledTheme);
	};

	return (
		<div style={{visibility: mounted ? "visible" : "hidden"}}>
			<ThemeProvider theme={{...themes[theme], toggleTheme}}>{props.children}</ThemeProvider>
		</div>
	);
};
