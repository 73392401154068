import styled from "styled-components";
import ThemeIcon from "@assets/svg/theme.inline.svg";

export const StyledToggle = styled.label`
	display: inline-flex;
	align-items: center;
	cursor: pointer;
`;

export const ToggleRail = styled.div`
	isolation: isolate;
	position: relative;

	box-sizing: content-box;
	padding: 1px;
	border-radius: clamp(15px, 4vw, 30px);
	border: 2px solid ${({theme}) => theme.textColor};

	width: clamp(30px, 5vw, 50px);
	aspect-ratio: 2/1;

	overflow: hidden;
`;

export const CheckBox = styled.input`
	display: none;
`;

export const Indicator = styled.div<{checked: boolean}>`
	display: flex;
	align-content: center;
	align-items: center;

	border-radius: 50%;

	height: 100%;
	width: 50%;

	transform: ${({checked}) => (checked ? "translate3d(100%, 0, 0)" : "translate3d(0, 0, 0)")};

	transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
`;

export const StyledThemeIcon = styled(ThemeIcon)`
	height: 100%;
	width: 100%;

	fill: ${({theme}) => theme.textColor};
`;
