import {Theme, Themes} from "@interfaces/Themes";

export const themes: Record<Themes, Theme> = {
	light: {
		type: "light",
		borderRadius: "12px",
		shadowBox: "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0)",
		textColor: "#141c3a",
		invertedTextColor: "#fff",
		primaryColor: "#fca311",
		primaryBackgroundColor: "#fca311",
		secondaryColor: "#141c3a",
		secondaryActiveColor: "#1e2a54",
		backgroundColor: "#fff",
	},
	dark: {
		type: "dark",
		borderRadius: "12px",
		shadowBox: "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0)",
		textColor: "#fff",
		invertedTextColor: "#fff",
		primaryColor: "#fca311",
		primaryBackgroundColor: "#0e0e0e",
		secondaryColor: "#080808",
		secondaryActiveColor: "#121212",
		backgroundColor: "#080808",
	},
};
