import * as React from "react";
import {useStaticQuery, graphql, navigate} from "gatsby";
import {useContext} from "react";
import {LocaleContext} from "@context/LocaleContext";
import {contactMeForm} from "@data/translation/contactMeForm";
import {MessageField, StyledForm, ShortFields, StyledSection, BotHoneyPot} from "./style";

export const ContactMeSection = () => {
	const locale = useContext(LocaleContext);
	const {titleLabel, nameLabel, emailLabel, messageLabel, sendLabel} = contactMeForm[locale];

	const {site} = useStaticQuery(graphql`
		query MyQuery {
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	return (
		<StyledSection
			id="contact-me"
			onSubmit={() => {
				navigate(`/${locale}/thanks`);
			}}>
			<h1>{titleLabel}</h1>
			<StyledForm action={`https://formsubmit.io/send/${process.env.GATSBY_FORMSUBMIT_IO_KEY}`} method="POST">
				<input type="hidden" name="_url" value="https://monknow.dev"></input>
				<ShortFields>
					<p>
						<label htmlFor="name">{nameLabel}</label>
						<input type="text" required name="name" id="name" />
					</p>
					<p>
						<label htmlFor="email">{emailLabel}</label>
						<input type="email" required name="email" id="email" />
					</p>
				</ShortFields>
				<MessageField>
					<label htmlFor="message">{messageLabel}</label>
					<textarea required name="message" id="message"></textarea>
				</MessageField>
				<BotHoneyPot />
				<button type="submit">{sendLabel}</button>
			</StyledForm>
		</StyledSection>
	);
};
