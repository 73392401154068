import * as React from "react";
import {GatsbyBrowser} from "gatsby";
import {LocaleProvider} from "./src/context/LocaleContext";
import {URLProvider} from "./src/context/URLContext";
import {StyledThemeProvider} from "./src/context/ThemeContext";
import {GlobalStyles} from "./src/global/GlobalStyles";
import {Layout} from "./src/components/Layout";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({element, props}) => {
	const {pageContext, location} = props;

	return (
		<LocaleProvider locale={pageContext.locale}>
			<URLProvider location={location}>
				<StyledThemeProvider>
					<GlobalStyles />
					<Layout>{element}</Layout>
				</StyledThemeProvider>
			</URLProvider>
		</LocaleProvider>
	);
};

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({routerProps: {location}}) => {
	if (location.hash !== "") {
		return true;
	}

	const transitionDelay = 400;

	setTimeout(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "instant",
		});
	}, transitionDelay);

	return false;
};

export const onServiceWorkerUpdateReady: GatsbyBrowser["onServiceWorkerUpdateReady"] = () => {
	const answer = window.confirm(`This application has been updated. ` + `Reload to display the latest version?`);
	if (answer === true) {
		window.location.reload();
	}
};
