import {SocialMediaItem} from "@interfaces/SocialMediaItem";

export const socialMediaItems: SocialMediaItem[] = [
	{
		name: "instagram",
		url: "https://www.instagram.com/monknow_dev/",
	},
	{
		name: "linkedin",
		url: "https://www.linkedin.com/in/monknow/",
	},
	{
		name: "twitter",
		url: "https://twitter.com/monknow_dev",
	},
];
