import {Locale} from "@data/locales";

interface contactMeFormItem {
	titleLabel: string;
	nameLabel: string;
	emailLabel: string;
	messageLabel: string;
	sendLabel: string;
}

export const contactMeForm: Record<Locale, contactMeFormItem> = {
	en: {
		titleLabel: "How can I help you?",
		nameLabel: "Name",
		emailLabel: "Email",
		messageLabel: "Message",
		sendLabel: "Send",
	},
	es: {
		titleLabel: "¿Cómo puedo ayudarte?",
		nameLabel: "Nombre",
		emailLabel: "Correo",
		messageLabel: "Mensaje",
		sendLabel: "Enviar",
	},
};
