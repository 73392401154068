import styled from "styled-components";

export const StyledSection = styled.section`
	position: relative;
	top: 150px;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;

	margin: 0px auto;
	border-radius: ${({theme}) => theme.borderRadius};
	padding: 20px;

	width: calc(100vw - clamp(50px, 10vw, 100px));

	background-color: ${({theme}) => theme.secondaryColor};
	color: ${({theme}) => theme.invertedTextColor};
	box-shadow: ${({theme}) => theme.shadowBox};

	h1 {
		text-align: center;
	}
`;

export const StyledForm = styled.form`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;

	width: 94%;

	& p {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-flow: column;

		margin: 10px 0px;
	}

	& label {
		margin-bottom: 5px;

		font-size: 0.7em;

		color: #c0c0c0;
	}
`;

export const ShortFields = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-flow: row wrap;

	width: 100%;

	& p {
		width: clamp(250px, calc(50% - 20px), 500px);

		@media (max-width: 700px) {
			& {
				width: 100%;
			}
		}
	}

	& input {
		height: 40px;
		width: 100%;

		border: 1px solid #a1a1a1;
		border-radius: 4px;
		outline: none;
		padding-left: 8px;

		font-family: "Open Sans Light";
		font-size: 0.9em;

		background-color: ${({theme}) => theme.secondaryColor};
		color: ${({theme}) => theme.invertedTextColor};

		&:focus {
			border: 1px solid ${({theme}) => theme.primaryColor};
		}
	}
`;

export const MessageField = styled.p`
	width: 100%;
	& textarea {
		resize: none;
		width: 100%;
		height: clamp(150px, 10vw, 200px);

		border: 1px solid #a1a1a1;
		border-radius: 4px;
		outline: none;
		padding: 5px 0px 0px 8px;

		font-family: "Open Sans Light";
		font-size: 0.9em;

		background-color: ${({theme}) => theme.secondaryColor};
		color: ${({theme}) => theme.invertedTextColor};

		&:active,
		&:focus {
			border: 1px solid ${({theme}) => theme.primaryColor};
		}
	}
`;

export const BotHoneyPot = styled.input`
	display: none;
`;
