module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Monknow.dev","description":"Monknow's personal portfolio and blog","short_name":"Monknow.dev","start_url":"/","background_color":"#ffffff","theme_color":"#fca311","display":"standalone","icon":"./src/assets/images/android-chrome-512x512.png","icons":[{"src":"./src/assets/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"./src/assets/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"./src/assets/images/maskable_icon.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"237fb7a3582df41fbbe11d2032729a66"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
