import * as React from "react";
import {NavBar} from "@components/NavBar";
import {Footer} from "@components/Footer/index";
import {ContactMeSection} from "@components/ContactMeSection";
import {AnimatePresence} from "framer-motion";
import {Props} from "./interface";

export const Layout = ({children, localized}: Props) => {
	return (
		<div>
			<NavBar localized={localized} />
			<AnimatePresence mode="wait" initial={false}>
				{children}
			</AnimatePresence>
			<ContactMeSection />
			<Footer />
		</div>
	);
};
