import {Locale} from "@data/locales";

interface Credits {
	quote: string;
	credits: string;
}

export const credits: Record<Locale, Credits> = {
	en: {
		quote: "Learning and improving more and more",
		credits: "Page programmed by me",
	},
	es: {
		quote: "Aprendiendo y mejorando cada vez más",
		credits: "Página programada por mí",
	},
};
