import styled from "styled-components";

export const StyledFooter = styled.footer`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	gap: 10px;

	padding-top: 80px;

	height: 100vh;

	text-align: center;

	background-color: ${({theme}) => theme.primaryBackgroundColor};
	color: ${({theme}) => theme.invertedTextColor};

	p {
		font-size: 0.9em;
	}
`;
