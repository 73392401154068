import {LocalizedLink} from "@components/LocalizedLink";
import styled, {createGlobalStyle} from "styled-components";

export const StyledNavBar = styled.nav`
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 99;

	display: grid;
	grid-template-columns: auto 1fr auto auto auto;
	grid-template-rows: 1fr;
	grid-template-areas: "logo . language theme burger";
	align-items: center;
	justify-items: center;
	gap: 0px clamp(6px, 6vw, 50px);

	height: 10vh;
	width: 100dvw;

	padding: 0px clamp(10px, 5vw, 40px);

	font-size: clamp(12px, 3vw, 16px);

	background-color: ${({theme}) => theme.backgroundColor};
	box-shadow: ${({theme}) => theme.shadowBox};

	.logo {
		grid-area: logo;
		justify-self: start;
	}

	.language {
		grid-area: language;
	}

	.theme {
		grid-area: theme;
		justify-self: end;
	}

	.burger {
		justify-self: end;
		grid-area: burger;
	}
`;

export const StyledToggleLanguage = styled(LocalizedLink)`
	text-align: center;

	color: ${({theme}) => theme.textColor};
`;

export const NavBarSpace = createGlobalStyle`
	html{
		scroll-padding-top: 10vh;
	}
	body {
		padding-top: 10vh;
	}
`;
