import * as React from "react";
import {createContext, FC, PropsWithChildren} from "react";

interface Props extends PropsWithChildren {
	location: Window["location"];
}

export const URLContext = createContext({href: "http://monknow.dev/en", pathname: "en"});

export const URLProvider: FC<Props> = (props) => {
	return <URLContext.Provider value={props.location}>{props.children}</URLContext.Provider>;
};
