import {createGlobalStyle} from "styled-components";
import "@assets/fonts/fonts.css";

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;

    }
    
    html{
        
        font-family: "Open Sans Regular", sans-serif;
        font-size: clamp(12px, 4vw, 16px);
        scroll-behavior: smooth;
        overflow-x: hidden;

    }
    
    body{
        width: 100vw;

        background-color: ${({theme}) => theme.backgroundColor};
        color: ${({theme}) => theme.textColor};
        
    }

    h1{
        font-size: 2em;
        font-family: "Open Sans Bold", sans-serif;
    }

    h2{
        font-size: 1.2em;
        font-family: "Open Sans Semibold", sans-serif;
    }
    


    a{
        text-decoration: none;
    }

    button{
        width: fit-content;

        margin: 10px 0px;
        border: 2px solid ${({theme}) => theme.primaryColor};
        border-radius: 20px;
        padding: clamp(3px, 1vw, 6px) clamp(8px, 4vw, 24px);
        box-sizing: content-box;

        font-family: "Open Sans Bold", sans-serif;
        font-weight: 600;
        font-size: clamp(14px, 2vw, 20px);
        
        text-decoration: none;
        text-align: center;

        background-color: transparent;
        color: ${({theme}) => theme.primaryColor};

        cursor: pointer;

        transition: all 100ms;

        &:hover,
        &:focus {
            background-color: ${({theme}) => theme.primaryColor};
            color: ${({theme}) => theme.backgroundColor};
        }
    }
`;
