import {Locale} from "@data/locales";

interface NavBarItem {
	slug: string;
	path: string;
	inPage: boolean;
	content: Record<Locale, string>;
}

export const navBarItems: NavBarItem[] = [
	{
		slug: "about-me",
		path: "#about-me",
		inPage: false,
		content: {
			en: "About Me",
			es: "Sobre mí",
		},
	},
	{
		slug: "skills",
		path: "#skills",
		inPage: false,
		content: {
			en: "Skills",
			es: "Habilidades",
		},
	},
	{
		slug: "portfolio",
		path: "/portfolio",
		inPage: false,
		content: {
			en: "Portfolio",
			es: "Portafolio",
		},
	},
	{
		slug: "blog",
		path: "/blog",
		inPage: false,
		content: {
			en: "Blog",
			es: "Blog",
		},
	},
	{
		slug: "contact-me",
		path: "#contact-me",
		inPage: true,
		content: {
			en: "Contact me",
			es: "Contáctame",
		},
	},
];
