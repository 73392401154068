import {FC} from "react";
import InstagramIcon from "@assets/svg/iconmonstr-instagram-11.inline.svg";
import LinkedInIcon from "@assets/svg/iconmonstr-linkedin-1.inline.svg";
import TwitterIcon from "@assets/svg/iconmonstr-twitter-1.inline.svg";

export const socialMediaIcons: Record<"instagram" | "twitter" | "linkedin", FC> = {
	instagram: InstagramIcon,
	linkedin: LinkedInIcon,
	twitter: TwitterIcon,
};
