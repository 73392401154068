import styled from "styled-components";

export const StyledMenu = styled.div`
	height: 40px;
	width: 40px;
`;

export const BurgerContainer = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 100%;
	width: 100%;
	cursor: pointer;
`;

export const StyledBurger = styled.div`
	&,
	&::before,
	&::after {
		display: block;
		background-color: ${({theme}) => theme.textColor};
		position: absolute;
		margin-top: 0px;

		height: 4px;
		width: 30px;
		transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
		border-radius: 2px;
	}

	&::before {
		content: "";
		margin-top: 8px;
	}

	&::after {
		content: "";
		margin-top: -8px;
	}
`;

export const StyledCheckbox = styled.input`
	display: none;

	&:checked {
		& + .burger-container .burger {
			background: rgba(255, 255, 255, 0);
		}

		& + .burger-container .burger::before {
			margin-top: 0px;
			transform: rotate(45deg);
		}
		& + .burger-container .burger::after {
			margin-top: 0px;
			transform: rotate(-45deg);
		}
	}

	&:checked ~ ul {
		opacity: 1;
		pointer-events: auto;
	}
`;

export const StyledLinks = styled.ul`
	position: absolute;
	right: 40px;
	z-index: 100;

	border-radius: 4px;

	display: flex;
	flex-flow: column nowrap;

	text-align: right;

	list-style: none;

	opacity: 0;
	pointer-events: none;
	transition: 0.3s all;

	box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);

	a {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		padding: clamp(14px, calc(25px - 2vw), 20px) clamp(16px, calc(30px - 2vw), 24px);

		font-size: clamp(12px, calc(20px - 2vw), 16px);

		background-color: ${({theme}) => theme.secondaryColor};
		color: ${({theme}) => theme.invertedTextColor};
	}

	a:hover {
		background-color: ${({theme}) => theme.secondaryActiveColor};
	}

	li:last-child {
		a {
			border-radius: 0px 0px 4px 4px;
		}
	}

	li:first-child {
		a {
			border-radius: 4px 4px 0px 0px;
		}
	}
`;
