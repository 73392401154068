import * as React from "react";
import {HTMLAttributes, useContext} from "react";
import {LocaleContext} from "@context/LocaleContext";
import {useToggleLocale} from "@hooks/useToggleLocale";
import {StyledLink} from "./style";

interface LocalizedLinkProps extends HTMLAttributes<HTMLAnchorElement> {
	to: string;
	toggleLocale?: boolean;
}

export const LocalizedLink = ({to, toggleLocale, children, ...rest}: LocalizedLinkProps) => {
	const locale = toggleLocale ? useToggleLocale() : useContext(LocaleContext);

	return (
		<StyledLink to={`/${locale}${to}`} {...rest}>
			{children}
		</StyledLink>
	);
};
